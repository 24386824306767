@import "./ds/onboarding.scss";
@import "./ds/ds-accounts-panel.scss";
@import "./ds/ds-reusable.scss";
@import "./ds/dashboard/ds-dashboard.scss";
@import "./ds/inputs/inputs.scss";
@import "./ds/inputs/searchbar.scss";


:root {
    --ds-onboarding-subtitle-color: #7e7e7e;
    --ds-onboarding-title-mbottom: 24px;

    --ds-onboarding-texts-mbottom: 56px;

    --ds-onboarding-input-mbottom: 24px;
}