@mixin flexVerticalCenter {
    display: flex;
    align-items: center;
}

@mixin title20 {
    font-size: 20px;
    font-weight: bold;
}

@mixin title18 {
    font-size: 18px;
    font-weight: bold;
}

@mixin title16 {
    font-size: 16px;
    font-weight: bold;
}

@mixin subtitle16 {
    font-size: 16px;
    color: var(--subtitle-font-color);
}

@mixin subtitle14 {
    font-size: 14px;
    color: var(--subtitle-font-color);
}