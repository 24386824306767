.ds-onboarding {
    width: 100vw;
    height: 100vh;

    position: relative;

    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    &-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 50%; // media queries
        max-width: 700px;
        margin: auto;
    }

    &-texts {
        width: 100%;

        margin-bottom: var(--ds-onboarding-texts-mbottom);
        &-title {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: var(--ds-onboarding-title-mbottom);
        }

        &-subtitle {
            color: var(--ds-onboarding-subtitle-color);
            font-size: 18px;
            font-weight: 300;
        }
    }

    &-input {
        width: 100%;
        margin-bottom: var(--ds-onboarding-input-mbottom);

        .ds-input-primary-content input {
            font-size: 16px !important;
            padding: 24px 18px !important;
            width: 100%;
        }

        .ds-input-primary-label {
            font-size: 14px;
        }
    }

    &-button {
        width: 100%;
      display: flex;
      justify-content: flex-end;  

      button {
        font-size: 15px;
        height: 48px;
        max-height: 48px;
        min-width: 140px;
      }
    }

    &-totalumlogo-container {
        width: 100%;
        padding-left: 32px;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    &-totalumlogo {
        width: 60px;

        img {
            width: 100%;
        }
    }

    &-footer {
        margin-bottom: 24px;
        div {
            text-align: center;
            color: #696969;

            font-weight: 300;

            a {
                color: var(--ds-primary-color);
                font-size: 15px;
                font-weight: 300;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            
        }

        div:last-child {
            margin-top: 8px;
        }
    }
}

.ds-bigtotalumloader {
    width: 100%;

    // center vertically
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    &-totalumlogo {
        width: 160px;
        padding: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        animation: pulse 2.5s infinite ease-in-out;
        transition: 1s all ease, box-shadow 1s ease; /* Smooth transition for transform and box-shadow */
            //animation-delay: 1s; /* Add a delay to stagger the animations */

        img {
            width: 100%;
        }
        user-select: none;
    }

    &-totalumlogo-big {
        width: 250px;
        padding: 52px;
        box-shadow: 0px 0px 8px 0px rgba(102, 255, 0, 0.25);
        animation: none;
    }

    &-progressbar {
        max-width: 200px;
        width: 100%;
        margin-top: 36px;
    }

    &-progressbar-bar {
        height: 8px;
        background: #0066FF;
        border-radius: 5px;
        //width: 10%;
        transition: 0.5s all ease;
    }

    &-text {
        position: absolute;
        bottom: 30%;
        font-size: 36px;
        color: #565656;
        font-weight: 300;
        transition: 1s all ease;
    }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Increase the scale to make it 'pulse' */
    }
    100% {
      transform: scale(1);
    }
  }

// all media queries for content

@media (max-width: 768px) {

    .ds-onboarding-totalumlogo-container {
        width: 100%;
        padding-left: 24px;
        padding-top: 24px;
        padding-bottom: 0;
    }

    .ds-onboarding-content {
        width: 100%;
        padding: 24px;
    }
}