@import './inputs.scss'; 

.ds-input-searchbar {

    ion-icon {
        font-size: var(--ds-input-searchbar-icon-size);
        margin-left: var(--ds-margin-icon-and-content);
        color: var(--ds-light-gray-font);
    }

    ion-input {
        // structure
        @extend %ds-ion-input;

        display: flex;
        align-items: center;

        height: 36px;

        --padding-start: var(--ds-margin-icon-and-content);
    }

}