.button-margin-top {
    margin-top: 24px;
}

.form-input-error {
    font-size: 14px;
    margin-top: 10px;
    color: #eb445a;
}

ion-item {
    --padding-start: 0 !important;

    ion-thumbnail {
        padding: 8px;
        border-radius: 6px;
    }
}

ion-radio-group ion-item {
    --padding-start: 16px !important;
}

.form-inputs-margin {
    margin-top: 16px;
}

.smaller-card-title {
    font-size: 22px;
}

ion-searchbar {
    .searchbar-input {
        height: 150px; /* Adjust the height as needed */
        font-size: 16px; /* Optionally adjust the font size */
        /* Add any other styles as needed */
      }
}

.modal-header-menu {
    width: 100%;
    position: fixed;
    top: 0;
    background: rgba(0,0,0,.3);
    z-index: 100000;
    font-family: 'Open Sans', sans-serif;

    &-backbutton {
        display: flex;
        align-items: center;
        color: white;
        padding: 12px 14px;
        font-size: 18px;

        ion-icon {
            margin-right: 6px;
            color: white;
            font-size: 23px;
            margin-bottom: 1px;
        }
    }
}

.cart-item {
    width: 100%;
    padding: 16px 0;
        border-bottom: 1px solid rgb(206, 206, 206);

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-img {
            img {
                width: 100%;
            }
            display: flex;
            align-items: center;
            width: 40px;
        }

        &-info {

            &-title {
                font-size: 16px;
                font-weight: 600;
                width: 100%;
                text-align: right;
                color: black;
            }

            &-subtitle {
                font-size: 16px;
                color: #666666;
                width: 100%;
                text-align: right;
            }
        }
    }

    &-content {
    }
}

.quantity-button {
    --width: 60px;
    --height: 60px;
    --vertical-align: middle;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 8px;
    --padding-bottom: 8px;
}

.quantity-selector {
    display: flex;
    justify-content: center;
    align-items: center;

    ion-button {
        margin-left: 16px;
        margin-right: 16px;
    }
}


.inner-scroll {
    padding-bottom: 0 !important;
}

.quantity-selector-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 32px;
    font-size:18px;

    &-text {
        width: 80%;
        font-size: 16px;
    }
}

.loader {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}