.ds-chip {
    width: fit-content;
    padding: 6px 16px;
    border-radius: 16px;
    background-color: var(--ds-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    user-select: none;
    cursor: pointer;
    font-weight: 500;
}

.ds-card-pricing {
    box-shadow: 0px 0px 10px 0px rgba(188, 188, 188, 0.25);
    border-radius: 20px;
    padding: 24px;
    background: white;

    &-title {
        width: 100%;
        text-align: center;
        font-size: 24px;
        color: #4D4D4D;
        margin-bottom: 24px;
        font-weight: 600;

    }

    &-price {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        span.price-symbol {
            font-size: 21px;
            color: rgba(77, 77, 77, 0.5);
        }

        span.price-amount {
            font-size: 21px;
            color: #4D4D4D;
            font-weight: 700;
            margin-right: 4px;
        }

        span.price-period {
            font-size: 15px;
            color: rgba(77, 77, 77, 0.5);
        }
    }

    hr {
        border-bottom: 1px solid #D4D4D4;
        width: 80%;
        margin: auto;
        margin-bottom: 24px;
    }

    
    &-features {
        margin-bottom: 32px;

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            ion-icon {
                font-size: 24px;
                min-width: 24px;
                max-width: 24px;
                width: 24px;
                color: var(--ds-tertiary-color) !important;;
                margin-right: 8px;
            }

            span {
                font-size: 15px;
                color: #4D4D4D;
            }

        }
    }

    &-button {
        display: flex;
        justify-content: center;

        button {
            min-width: 180px;
            ion-icon {
                margin-right: 0;
                margin-left: 8px;
            }
            font-size: 14px;
            font-weight: 500;
            border-radius: 20px;
        }

        .sp-button-tertiary {
            ion-icon {
                margin-right: 16px !important;
                margin-left: 0;
            }
        }
    }
}

.ds-block-propertyandvalue-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-property {
        font-size: 14px;
        color: #9496A3;
        font-weight: 500;
    }

    &-value {
        font-size: 14px;
        color: black;
        font-weight: 500;
    }
}