@import "../mixins/general.scss";

.ion-button-clear {
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    --padding-start: 0 !important;
}

.smart-input-vertical-wrapper {
    margin-bottom: 12px;
}

.smart-inputs-max-width {

    .smart-input {
        max-width: 400px;
    }
}

.smart-input-inline-wrapper {
    display: inline-block;

    .smart-input {
        width: 100%;
    }
}

.smart-input {
    // max-width: 320px;
    // min-width: 250px;
    /*height: 48px;
    max-height: 48px;*/
    min-width: 300px;

    &-top {
        margin-bottom: 5px;
        color: #3a3a3a;
        //font-family: 'Lato', sans-serif;
    }

    &-bottom {
        border: 1px solid #d8d7d7;
        border-radius: 6px;
        padding: 3.5px 9px;
        position: relative;
        display: flex;
        align-items: center;

        &:focus-within {
            border-color: var(--header-bg-color);
        }
    }

    &-tag {
        font-weight: bold;
        margin-right: 12px;
        margin-left: 12px;
    }

    &-tag:first-child {
        font-weight: bold;
        margin-right: 12px;
        margin-left: 0;
    }

    ion-input {
        width: auto;
    }

    ion-select {
        width: 100%;
        --padding-start: 6px;
    }

    &-note {
        position: absolute;
        right: 0;
        bottom: -24px;
        z-index: 1000000;
        font-size: 14px;
        color: var(--font-color-lighter);
    }

    &-symbol {
        width: 20px;
        text-align: center;
    }

    &-button {
        padding-left: 4px;
        padding-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        ion-icon {
            color: var(--button-bg-primary);
            font-size: 20px;
            padding: 6px;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background: rgb(56 152 204 / 9%);
            }
        }
    }
}

.smart-input-large {
    max-width: 400px;
    min-width: 400px;

    ion-select {
        width: 100%;
    }

    .smart-input-tag {
        width: 100% !important;
    }
}

.smart-input-long {
    max-width: 400px;
    min-width: 400px;
}

.smart-input-checkbox {
    max-width: 300px;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    background: white;

    border: 1px solid #e4e4e4;
    border-radius: 6px;
    padding: 12.5px 14px;
    position: relative;
    display: flex;
    align-items: center;

}

.smart-input-objectReference {
    height: auto;
    max-height: none;
}

.smart-input-textarea {
    display: block;
    max-height: 500px;
    height: auto;

    &-tag {
        width: 100%;
        margin-bottom: 4px;
    }

    ion-textarea {
        --padding-start: 0px;
        overflow-y: auto;
        max-height:300px;
    }
}

.checkbox-selector {
    &-title {
        font-weight: bold;
        margin-bottom: 12px;
    }

    &-group {
        @include flexVerticalCenter();
        flex-wrap:wrap;
    }
}

.group-checkbox {
    @include flexVerticalCenter();
    padding: 4px 8px;
    cursor: pointer;

    &-checkbox {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        @include flexVerticalCenter();


        ion-checkbox::part(container) {
            width: 24px;
            height: 24px;
            padding: 4px;
        }
    }

    &-checkbox-smaller {
        margin-right: 12px;
        width: 21px;
        height: 21px;
        @include flexVerticalCenter();


        ion-checkbox::part(container) {
            width: 21px;
            height: 21px;
            padding: 4px;
        }
    }

    &-tag {
    }

    
}


.element-reference-box {
    position:absolute;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: #EDEDED;
    z-index:100000000000000;
    pointer-events: none;

    &-content {
        display: flex;
        align-items: center;

        &-reference {
            max-width: 130px;

            display: flex;
            align-items: center;

            padding: 4px 6px;
            background: #D4D4D4;
            border-radius: 6px;
            cursor: pointer;

            &-label {
                margin-right: 2px;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
            &-delete {
                display: flex;
                align-items: center;
                font-size: 20px;
                pointer-events: all;
            }
        }
        
    }
}

.smart-input-fileselector {
    max-width: inherit;
    min-width: 250px;
}


.smart-input-filebox {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;

    input {
    }

    .element-reference-box {
    left: 0;
        max-width: 220px;
        .element-reference-box-content-reference {
            max-width: 220px;

        }
    }

    &-placeholder {
        max-width: 100%;
        display: flex;
        align-items: center;
        color: var(--button-bg-primary);
        white-space: nowrap; overflow: hidden; text-overflow: ellipsis;

        ion-icon {
            width: 20px;
            min-width: 20px;
            margin-right: 6px;
            font-size: 20px;
        }
    }
}

.smart-input-filebox {
    height: 35px;

    &-box {
        display: flex;
        align-items: center;

        padding: 4px 6px;
        background: #D4D4D4;
        background: #bdd7f3;
        border-radius: 6px;
        color: #515151;
        cursor: pointer;
        position: relative;
        height: 100%;

        &-text {
            white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
            max-width: 230px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        ion-icon {
            margin-right: 6px;
            width: 20px;
            min-width: 20px;
            max-width: 20px;
        }

        &-delete {
            position: absolute;
            right: 0;
            min-width: initial;
            max-width: initial;
            width: initial;
            font-size: 25px;
            padding-left: 4px;
            background: #bdd7f3;
            z-index: 100000000000000000000000000000000;
        }
    }

}

.smart-input-objselectorwidth {
    width: 100%;
}