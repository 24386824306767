.ds-dashboard-block {
    box-shadow: 0px 0px 10px 0px rgba(188, 188, 188, 0.25);
    border-radius: 12px;
    padding: 24px;

    &-border {
        border: 1px solid #E5E5E5;
        box-shadow: none;
        border-radius: 8px;
    }

    &-inline-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-right {
            display: flex;
            align-items: center;

            .ds-dashboard-category-icon {
                margin-right: 12px;
                width: 33px;
                height: 33px;
                padding: 8px;

                ion-icon {
                    font-size: 17px;
                }
            }
        }

        &-texts {
            &-title {
                font-weight: bold;
                font-size: 16px;
            }

            &-subtitle {
                color: #565656;
                font-size: 13px;
            }
        }

        &-left {
            ion-icon {
                font-size: 24px;
                color: #565656;
                cursor: pointer;
            }
        }
    }

    &-header {
        margin: auto;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-title {
            font-size: 16px;
            font-weight: 600;
            color: #373737;
            width: 100%;
            text-align: center;
            margin-top: 8px;
        }
    
    }


    &-content {
        margin-top: 12px;
        margin-bottom: 24px;
        color: #565656;
        font-weight: 300;
        width: 100%;
        font-size: 14px;

        &-bottombutton {
            margin: auto;
            text-align: center;
            margin-top: 24px;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
    }

    &-use-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .ds-dashboard-use, .ds-dashboard-use-bars {
            /*max-width: 180px;
            width: 180px;*/
            min-width: 180px;
            width: 22%;
            margin-right: 16px;
            margin-top: 16px;
        }
    }

    &-tutorials-row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;

        .ds-dashboard-tutorial-block {
            width: 31%;
            margin-right: 1%;
            margin-top: 1%;
        }
    }
}

.ds-dashboard-use {
    border: 1px solid #E5E5E5;
    padding: 16px;
    border-radius: 6px;

    &-title {
        color: #565656;
        font-size: 14px;
        text-align: center;
        margin-bottom: 4px;
    }

    &-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 12px;

        &-quantity {
            font-weight: bold;
            color: black;
            font-size: 18px;
        }

        &-limit {
            color: #c5c5c5;
            font-size: 15px;
        }
    }

    &-progressbar {
        background: #EDEDED;
        border-radius: 100px;
        height: 8.5px;
        width: 100%;

        &-activebar {
            background: var(--ds-primary-color);
            border-radius: 100px;
            height: 8.5px;
            width: 50%;
        }

        &-activebar-limit {
            border-radius: 100px;
            height: 8.5px;
            width: 50%;
            background: #FF9F2D;
        }
    }
}

.ds-dashboard-use-bars {
    border: 1px solid #E5E5E5;
    padding: 16px;
    border-radius: 6px;

    &-element {
        margin-bottom: 18px;

        &-title {
            font-size: 14px;
            color: #565656;
            margin-bottom: 6px;

            .bars-element-title-quantity {
                color: black;
                font-weight: bold;
            }

            .bars-element-title-limit {
                color: #CECECE;
                font-size: 13px;
            }
        }

        &-progressbar {
            background: #EDEDED;
            border-radius: 100px;
            height: 7px;
            width: 100%;

            &-activebar {
                background: var(--ds-primary-color);
                border-radius: 100px;
                height: 7px;
                width: 50%;
            }

            &-activebar-limit {
                border-radius: 100px;
                height: 7px;
                width: 50%;
                background: #FF9F2D;
            }
        }

        &:last-child {
            margin-bottom: 0;
        
        }
    }
}

.ds-dashboard-category-icon {

    background: #F1F3F5;
    border-radius: 50%;
    width: 44px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px;
    
    ion-icon {
        color: #373737;
        font-size: 22px;
    }
}

.ds-dashboard-dashboard {
    max-width: 1250px;
    margin-top: 84px;


    &-left {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start; /* Align items to the start of the cross axis */
    }

    &-box {
        margin: 2%;
        margin-top: 0;
    }
}

.ds-dashboard-tutorial-block {
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;

    &-title {
        font-size: 16px;
        font-weight: bold;
        color: #373737;
        margin-bottom: 8px;
    }

    &-content {
        font-size: 14px;
        color: #565656;
        font-weight: 300;
    }

    &-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }
}

.ds-dashboard-modal {

    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000000000000000;
    background: rgba(0, 0, 0, 0.05);

    display: flex;
    justify-content: center;
    align-items: flex-start;
    

    &-content {
        box-shadow: 0px 0px 10px 0px rgba(188, 188, 188, 0.25);
        border-radius: 12px;
        padding: 24px;
        
        width: 50%;
        min-height: 400px;
        max-height: 75vh;
        margin-top: 64px;
    
        background: white;
        overflow-y: auto;

        .ds-dashboard-block-use-row {
            justify-content: initial;
        }
    }

 

}

.ds-dashboard-header {
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid #F1F3F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 58px;
    position: fixed;
    top: 0;
    background: white;
    z-index: 10000000000;
    overflow-x: auto;

    &-left {
        display: flex;
        align-items: center;

        &-totalumlogo {
            width: 32px;
            margin-right: 8px;

            img {
                width: 100%;
            }
        }

        &-totalumname {
            margin-right: 16px;
            font-size: 16px;
            color: #000000;
        }

        &-plan {
            font-size: 14px;
            color: #6E7A8A;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        gap: 16px;
        
        &-userinfo {
            display: flex;
            align-items: center;

            ion-icon {
                font-size: 24px;
                color: #6E7A8A;
                margin-right: 8px;
            }

            span {
                font-size: 14px;
                color: #6E7A8A;
            }
        }

        &-navigation-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &-navigation-button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 8px;
            font-size: 14px;
            color: #5e5e5e;
            cursor: pointer;
            transition: 0.5s all ease;
            font-weight: 400;
            text-decoration: none;

            &-active {
                background: #F1F3F5;
                color: #000000;
                font-weight: 600;
            }

            &:hover {
                background: #f4f4f5;
            }
        }


    }
}
// for small laptop and desktop
@media (min-width: 1024px) {
    .ds-dashboard-modal {
        &-content {
            width: 70%;
        }
    }
}

@media (max-width: 1024px) {
    .ds-dashboard-modal {
        &-content {
            width: 100%;
        }
    }
}