/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "./theme/common.scss";
@import "./theme/mixins/general.scss";
@import "./theme/components/inputs.scss";
@import "./theme/design-system.scss";

@import "/node_modules/flag-icons/css/flag-icons.min.css";

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;

}

.ds-input-primary-content, .ds-input-primary-content input {
    background: white !important;
}

.modal-too-much-elements {
    --height: auto;
    --width: 500px;
  }

ion-popover {
    --width: 200px;
    z-index: inherit !important;
}

.phone-extension-popover {
    --width: 350px;
    pointer-events: none;
    
}

.phone-extension-popover .popover-content {
    pointer-events: none;
}

.phone-extension-popover .popover-content .popover-wrapper {
    pointer-events: auto;

}

ion-alert.confirmation-alert {
    --width: 150px;
    --height: 100px;
}

ion-alert {
    z-index: 10000000000000000!important;
}

// if is big screen (more than 1000px) the ionmodal shoud have a fixed width
@media (min-width: 1000px) {
    ion-modal {
        --width: 950px;
    }
}

.confirmation-alert {
    .alert-wrapper {
        --max-width: auto;
        --width: 350px;
        --height: auto;
        padding: 8px;
    }
}


.button-primary {
    --background:  #387CCC;
    --background-hover: #2d67a9;
    --border-radius: 8px;
    height: 36px;
    --background-activated:  #2d67a9;
    
    ion-icon {
       font-size: 17px;
    }
}

.button-secondary {
    --background:  #F2F2F2;
    --background-hover: #E6E6E6;
    --color:  #387CCC;
    --border-radius: 8px;
    height: 36px;
    --background-activated:  #E6E6E6;

     ion-icon {
        font-size: 17px;
     }
}

.button-tertiary {
    --background:  #3dc2ff;
    --background-hover: #309cce;
    --border-radius: 8px;
    height: 36px;
    --background-activated:  #E6E6E6;

     ion-icon {
        font-size: 17px;
     }
}

.button-success {
    --background:  #28be64;
    --background-hover: rgb(28, 131, 69);
    --border-radius: 8px;
    height: 36px !important;
    max-height: none !important;
    --background-activated:  #E6E6E6;
    --padding-top: 12px;
    --padding-bottom: 12px;

     ion-icon {
        font-size: 17px;
     }
}

.no-pay-overlay {
    position: absolute;
    background: white;
    padding: 16px;
    border: 1px solid gray;
    border-radius: 8px;
    top: 100px;
    left: 40px;

    z-index: 100000000000;

    div {
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-size: 17px;
    }
}

ion-popover::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }