.ds-accounts-header {
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid #F1F3F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 58px;
    position: fixed;
    top: 0;
    background: white;
    z-index: 10000000000;
    overflow-x: auto;

    &-left {
        display: flex;
        align-items: center;

        &-totalumlogo {
            width: 32px;
            margin-right: 8px;

            img {
                width: 100%;
            }
        }

        &-totalumname {
            margin-right: 16px;
            font-size: 16px;
            color: #000000;
        }

        &-plan {
            font-size: 14px;
            color: #6E7A8A;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        gap: 16px;
        
        &-userinfo {
            display: flex;
            align-items: center;

            ion-icon {
                font-size: 24px;
                color: #6E7A8A;
                margin-right: 8px;
            }

            span {
                font-size: 14px;
                color: #6E7A8A;
            }
        }

        &-navigation-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &-navigation-button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 8px;
            font-size: 14px;
            color: #5e5e5e;
            cursor: pointer;
            transition: 0.5s all ease;
            font-weight: 400;

            &-active {
                background: #F1F3F5;
                color: #000000;
                font-weight: 600;
            }

            &:hover {
                background: #f4f4f5;
            }
        }


    }
}

.ds-accounts-body {
    background: #FAFAFA;
    width: 100%;
    flex-grow: 1;


    padding-top: 82px;

    &-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 32px;
    }

    &-subtitle {
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #6E7A8A;
        margin-bottom: 32px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-plans {
        display: flex;
        justify-content: center;
        align-items: flex-start; /* Align items to the start of the cross axis */
        flex-wrap: wrap; /* Allow the items to wrap as needed */
        gap: 24px;

        .ds-card-pricing {
            max-width: 300px;
            width: 300px;
        }
    }

    
    &-box {
        box-shadow: 0px 0px 10px 0px rgba(188, 188, 188, 0.25);
        border-radius: 20px;
        padding: 24px;
        margin-left: 24px;
        min-width: 50%;
        max-width: 50%;
        width: 50%;
        // todo: anadir media queries
        margin: auto;
        background: white;

        &-title {
            width: 100%;
            font-size: 24px;
            color: #4D4D4D;
            margin-bottom: 16px;
            font-weight: 600;
        }

        &-subtitle {
            width: 100%;
            font-size: 16px;
            color: #6E7A8A;
            margin-bottom: 24px;
        }

        &-form {
            .ds-input-primary {
                width: 100%;
                
                margin-bottom: 16px;

                input {
                    max-width: 300px;
                    width: 300px;
                }
            }

            &-button {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &-payment {
        margin: 0;
        margin-left: 24px;
        min-width: 40%;
        width: 40%;
        max-width: 40%;
    }


}

.ds-accounts-plan {
    background: white;
    padding: 16px;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    margin-bottom: 16px;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        &-left {
            display: flex;
            align-items: center;
    
            &-totalumlogo {
                width: 24px;
                margin-right: 8px;
    
                img {
                    width: 100%;
                }
            }
    
            &-totalumname {
                margin-right: 16px;
                font-size: 15px;
                color: #000000;
                font-weight: bold;
                margin-bottom: 2px;
            }
        }

        &-right {

            .ds-chip {
                font-size: 14px;
            }

            &-plan {
                font-size: 14px;
                color: #6E7A8A;
            }    
        }
    }

    &-content {
        .ds-block-propertyandvalue-horizontal {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}