%ds-ion-input {
    height: var(--ds-input-height);
    font-size: var(--ds-input-font-size);
    border-radius: var(--ds-input-border-radius);
    box-shadow: var(--ds-button-empty-border);
    padding: 0 var(--ds-input-padding-horizontal);
    --padding-start: var(--ds-input-padding-horizontal);
    --padding-end: var(--ds-input-padding-end);

    font-family: var(--ds-input-font-family) !important;

    &:focus-within {
        box-shadow: 0 0 0 2px var(--ds-primary-color);
        transition: 0.1s all ease;
    }

    &:disabled, &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.4;
    }

    transition: var(--ds-input-transition);
}

.ds-input-primary {
    position: relative;
    font-family: var(--ds-input-font-family) !important;

    &-label {
        font-size: var(--ds-input-label-font-size);
        color: var(--ds-input-label-color);
        margin-bottom: var(--ds-label-margin-bottom);

        font-weight: 500;
        transition: var(--ds-input-transition);
    }

    &-content {
        position: relative;

        &-radiochoice {
            display: flex;
            align-items: center;
            margin-right: 16px;
            font-size: 14px;
            cursor: pointer;
            user-select: none;
            min-width: 50px;
            ion-radio {
                margin-right: 8px;
            }
        }
        
        ion-radio-group {
            display: flex;
            align-items: center;
            margin-top: 14px;
        }

        ion-input, ion-select {
            // structure
            @extend %ds-ion-input;
            
        }

        &-filebox {
            display: flex;
            align-items: center;
            position: relative;
        }

        &-insertfile {
            position:relative;
        }

        ion-textarea {
            // structure
            @extend %ds-ion-input;
            height: var(--ds-input-textarea-height);
            --padding-start: var(--ds-input-textarea-padding);
            --padding-top: var(--ds-input-textarea-padding);
            --padding-bottom: var(--ds-input-textarea-padding);
            --padding-end: var(--ds-input-textarea-padding);
            padding: 0;
            overflow-y: auto; 

            .textarea-wrapper {
                height: 100%;
            }
        }

        &-symbol {
            position: absolute;
            right: 0;
            // align vertically
            top: 50%;
            transform: translateY(-50%);
            font-size: var(--ds-global-font-size);
            right: var(--ds-input-symbol-margin-right);
        }

        .ds-block-checkboxandtext {
            margin-top: var(--ds-input-margin-bottom);
        }


        &-references {
            &-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;
        
                margin-bottom: var(--ds-header-and-content-margin-bottom);
        
                button:first-child {
                    margin-right: 12px;
                }
            }
        }

        .ds-element-reference {
            margin-bottom: var(--ds-input-margin-bottom);
        }
    }

    &-content-checkboxes {
        display: flex;
        flex-wrap: wrap;

        .ds-block-checkboxandtext {
            margin-top: var(--ds-input-margin-bottom);
            width: 48%;
            margin-right: 2%;
        }
    }

    &-suggestions-container {
        width: 100%;
        position: relative;
    }

    &-suggestions {
        position: absolute;
        z-index: 100000000000000000000000000000000000;
        background: var(--ds-white-background);
        width: 100%;

        border: var(--ds-input-border);
        border-bottom-left-radius:var(--ds-input-border-radius);
        border-bottom-right-radius:var(--ds-input-border-radius);
        border-top: 0;
        
        padding: var(--ds-input-suggestions-padding);

        max-height: var(--ds-input-suggestions-max-height);
        overflow-x: auto;

        .ds-item-checkbox {
            margin-bottom: var(--ds-item-margin-between-items);
        }
    }

    &-range {
        display: flex;
        align-items: center;

        ion-input {
            // structure
            @extend %ds-ion-input;
        }

        &-separator {
            margin: 0 var(--ds-margin-icon-and-content);
            font-size: 13px;
            color: var(--ds-input-label-color);
        }
        
        .ds-button-icon-only {
            margin-left: var(--ds-margin-icon-and-content);
            i, ion-icon {
                font-size: 16px;
                min-width: 16px;
            }
        }
    }



}

.ds-input-primary-with-icon {
    display: flex;

    &-left {

    }

    &-right {
        margin-left: var(--ds-margin-icon-and-content);
        display: flex;
        align-items: flex-end;
        padding-bottom: 8px;

        i, ion-icon {
            font-size: 23px;
            min-width: 23px;
            cursor: pointer;
            color: var(--button-icon-color);
        }  
    }
}

.ds-input-primary-margin-bottom {
    margin-bottom: var(--ds-input-margin-bottom);

}

.ds-input-primary:focus-within .ds-input-primary-label {
    color: var(--ds-primary-color); /* Replace with your desired color */
}

.ds-input-primary:focus-within .ds-input-primary-content ion-input {
    box-shadow: 0 0 0 2px var(--ds-primary-color);
}


.ds-input-references {
    font-family: var(--ds-input-font-family) !important;

    &-label {
        font-size: var(--ds-input-label-font-size);
        color: var(--ds-input-label-color);
        margin-bottom: var(--ds-label-margin-bottom);

        font-weight: 500;
        transition: var(--ds-input-transition);
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        margin-bottom: var(--ds-header-and-content-margin-bottom);

        button:first-child {
            margin-right: 12px;
        }
    }

    &-references {

    }
}